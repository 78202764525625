import {useMutation} from '@apollo/client';
import {useLocation} from '@reach/router';
import {AddAnalyticEventDocument, AddAnalyticEventInput} from '../graphql-types';
import {ANALYTICS_ENABLED} from '../configs/vars';

export type LogAnalyticEventInput = Omit<AddAnalyticEventInput, 'pageUrl'>;

const useAnalyticsEvent = (input: LogAnalyticEventInput) => {
    const [logEvent] = useMutation(AddAnalyticEventDocument);
    const {pathname} = useLocation();

    return (paramVal?: string, paramData?: JSONValue) => {
        if (!ANALYTICS_ENABLED || !localStorage?.accountState) {
            return;
        }

        try {
            logEvent({
                variables: {
                    input: {
                        type: input.type,
                        value: paramVal || input.value,
                        data: paramData || input.data,
                        pageUrl: pathname,
                    },
                },
                onCompleted: () => { },
                onError: () => { },
            });
        } catch (e) { }
    };
};

export default useAnalyticsEvent;
